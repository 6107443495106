.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    margin: 0;
    padding: 20px;
  }
  
  .login-wrapper {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 400px;
    width: 100%;
  }
  
  .login-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .login-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .form-input {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    outline: none;
    font-size: 16px;
    transition: border-color 0.2s;
  }
  
  .form-input:focus {
    border-color: #3b82f6;
  }
  
  .login-button {
    background-color: #3b82f6;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .login-button:hover {
    background-color: #2563eb;
  }
  
  .login-message {
    margin-top: 15px;
    text-align: center;
    color: #d32f2f;
  }
  